<template>
    <div class="mainSpyContainer">

        <Hakkinda></Hakkinda>
        <SanYarisi></SanYarisi>
        <Elenme></Elenme>
        <Zafer></Zafer>
    </div>
</template>

<script>
    import Hakkinda from './Hakkinda';
    import SanYarisi from './SanYarisi';
    import Elenme from './Elenme';
    import Zafer from './Zafer';

    export default {
        components: {
            Hakkinda,SanYarisi,Elenme,Zafer
        }
    }
</script>



